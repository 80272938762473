import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BASE_PATH } from '@api-cc';
import { TranslateModule, TranslateParser } from '@ngx-translate/core';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { EmStateAction, EmStateEffect } from '@theia-cc/em/state';
import { CONSTANTS, ENVIRONMENT, ILocaleConfig, LOCALE_CONFIG } from '@theia-cc/shared/helpers';
import { SharedRootStepUiModule } from '@theia-cc/shared/root-step-ui';
import {
  EntryStepGuard,
  HttpErrorInterceptor,
  PartnerIdService,
  ShareLinkService,
  TranslateWithPartnerIdParser,
  getAppInitConfig,
  initAppWizardSettings,
} from '@theia-cc/shared/services';
import { SharedStoreModule, WizardAction } from '@theia-cc/shared/store';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxLoadingModule } from 'ngx-loading';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CONSTANTS_EM } from './configs/constants';
import { ParamsToCollectedDataResolverEm } from './core/params-to-collected-data.resolver';
import { ShareLinkServiceEm } from './core/share-link.service';
import { StoreModule } from './store/store.module';

const LOCALE_CONFIG_EM: ILocaleConfig = {
  LOCALES: ['de', 'fr', 'it'],
  LOCALE_STRINGS_FORMAL: {
    de: 'assets/locales/de-formal.json',
    fr: 'assets/locales/fr-formal.json',
    it: 'assets/locales/it-formal.json',
  },
};

if (!environment.production) {
  console.log(JSON.stringify(environment));
}

@NgModule({
  imports: [
    AlertModule.forRoot(),
    BrowserModule,
    RouterModule.forRoot(
      [
        {
          path: 'digital-upload',
          loadChildren: () => import('@theia-cc/digital-upload').then(m => m.DigitalUploadModule),
          data: { trade: 'em' },
        },
        {
          path: '',
          loadChildren: () => import('./steps/steps.module').then(m => m.StepsModule),
          resolve: { collectedData: ParamsToCollectedDataResolverEm },
          canActivate: [EntryStepGuard],
        },
      ],
      { scrollPositionRestoration: 'top' }
    ),
    BrowserAnimationsModule,
    DeviceDetectorModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      parser: {
        provide: TranslateParser,
        useClass: TranslateWithPartnerIdParser,
        deps: [PartnerIdService],
      },
    }),
    SharedRootStepUiModule,
    SharedStoreModule,
    StoreModule,
    DeviceDetectorModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ScullyLibModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    // Specify the second parameter as `true` to enable cms mode.
    getAppInitConfig(2, true),
    {
      provide: APP_INITIALIZER,
      useFactory: initAppWizardSettings,
      multi: true,
      deps: [WizardAction],
    },
    CookieService,
    { provide: ShareLinkService, useClass: ShareLinkServiceEm },
    EmStateAction,
    EmStateEffect,
    { provide: LOCALE_CONFIG, useValue: LOCALE_CONFIG_EM },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: BASE_PATH, useValue: environment.apiEndpoint },
    { provide: CONSTANTS, useValue: CONSTANTS_EM },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    ParamsToCollectedDataResolverEm,
  ],
})
export class AppModule {}
