import { NgRedux } from '@angular-redux/store';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NboEmobilityRequest } from '@api-cc';
import { IAppStateEm, ICollectedDataEm } from '@theia-cc/em/state';
import {
  caseAgnosticQueryParam,
  cleanEmptyProperties,
  getRestQueryParams,
} from '@theia-cc/shared/helpers';
import { IUserAddressParams, IUserInfoParams } from '@theia-cc/shared/models';
import {
  ISplitQueryParamsResultBase,
  ShareLinkService,
  USER_INFO_FIELDS,
} from '@theia-cc/shared/services';

export type ShareLinkQueryParams = Partial<ICollectedDataEm> &
  Partial<IUserInfoParams> &
  Partial<IUserAddressParams> & {
    partnerId?: string;
    Language?: string;
  };

export interface ISplitQueryParamsResultEm extends ISplitQueryParamsResultBase<ICollectedDataEm> {
  selectedTemplateIdEm: string;
  chargingPower: NboEmobilityRequest.ChargingPowerEnum;
}

@Injectable()
export class ShareLinkServiceEm extends ShareLinkService {
  constructor(
    private store: NgRedux<IAppStateEm>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super();
  }

  urlLinkQueryParamsSelector(state: IAppStateEm): any {
    const {
      currentLanguage: Language,
      config: {
        queryParams: { partnerId },
      },
    } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      partnerId,
      Language,
      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  shareLinkQueryParamsSelector(state: IAppStateEm): ShareLinkQueryParams {
    const {
      collectedData: {
        whereToInstallCharger,
        numberOfParkingspacesToBeEquippedResidential,
        parkingspace,
        poweroutlet,
        connectpv,
        fuseBox,
        chargingPower,
        orderType,
        totalNumberOfParkingspaces,
        numberOfParkingspacesToBeEquippedCommercial,
        numberOfParkingspacesPreparedToBeElectrified,
        distanceFuseboxToChargingstation,
        distanceFuseboxToFlatRibbonCable,
        selectedTemplateIdEm,
        numberOfRibbonCables,
        meterConnectionBetweenRibbonCable,
        parkingSpaceArrangement,
        checkType,
        userAddress: { lat, lon, place: City, street: Street, zip: ZipCode },
        user: {
          email: Email,
          firstName: FirstName,
          lastName: LastName,
          telephone: PhoneNumber,
          title: Title,
          companyName: CompanyName,
        },
      },
      currentLanguage: Language,
      config: {
        queryParams: { partnerId },
      },
    } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      whereToInstallCharger,
      numberOfParkingspacesToBeEquippedResidential,
      parkingspace,
      poweroutlet,
      connectpv,
      fuseBox,
      chargingPower,
      orderType,
      totalNumberOfParkingspaces,
      numberOfParkingspacesToBeEquippedCommercial,
      numberOfParkingspacesPreparedToBeElectrified,
      distanceFuseboxToChargingstation,
      distanceFuseboxToFlatRibbonCable,
      selectedTemplateIdEm,
      partnerId,
      Language,
      lat,
      lon,
      City,
      Street,
      ZipCode,
      Email,
      FirstName,
      LastName,
      PhoneNumber,
      Title,
      CompanyName,
      numberOfRibbonCables,
      meterConnectionBetweenRibbonCable,
      parkingSpaceArrangement,
      checkType,
      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  splitQueryParams<
    T = {
      collectedData: Partial<ICollectedDataEm>;
      browserCallback: string;
      partnerId: string;
      Language: string;
      restQueryParams: Partial<ShareLinkQueryParams>;
    }
  >(queryParams: ShareLinkQueryParams): T {
    const getCaseAgnosticQueryParam = caseAgnosticQueryParam(queryParams as any);

    const collectedData: Partial<ICollectedDataEm> = cleanEmptyProperties({
      whereToInstallCharger: getCaseAgnosticQueryParam('whereToInstallCharger'),
      numberOfParkingspacesToBeEquippedResidential: getCaseAgnosticQueryParam(
        'numberOfParkingspacesToBeEquippedResidential'
      ),
      parkingspace: getCaseAgnosticQueryParam('parkingspace'),
      poweroutlet: getCaseAgnosticQueryParam('poweroutlet'),
      connectpv: getCaseAgnosticQueryParam('connectpv'),
      fuseBox: getCaseAgnosticQueryParam('fuseBox'),
      chargingPower: getCaseAgnosticQueryParam('chargingPower'),
      orderType: getCaseAgnosticQueryParam('orderType'),
      totalNumberOfParkingspaces: getCaseAgnosticQueryParam('totalNumberOfParkingspaces'),
      numberOfParkingspacesToBeEquippedCommercial: getCaseAgnosticQueryParam(
        'numberOfParkingspacesToBeEquippedCommercial'
      ),
      numberOfParkingspacesPreparedToBeElectrified: getCaseAgnosticQueryParam(
        'numberOfParkingspacesPreparedToBeElectrified'
      ),
      distanceFuseboxToChargingstation: getCaseAgnosticQueryParam(
        'distanceFuseboxToChargingstation'
      ),
      distanceFuseboxToFlatRibbonCable: getCaseAgnosticQueryParam(
        'distanceFuseboxToFlatRibbonCable'
      ),
      selectedTemplateIdEm: getCaseAgnosticQueryParam('selectedTemplateIdEm'),

      userAddress: {
        lat: getCaseAgnosticQueryParam('lat'),
        lon: getCaseAgnosticQueryParam('lon'),
        place: getCaseAgnosticQueryParam('City'),
        street: getCaseAgnosticQueryParam('Street'),
        zip: getCaseAgnosticQueryParam('ZipCode'),
      },
      user: {
        email: getCaseAgnosticQueryParam('Email'),
        firstName: getCaseAgnosticQueryParam('FirstName'),
        lastName: getCaseAgnosticQueryParam('LastName'),
        telephone: getCaseAgnosticQueryParam('PhoneNumber'),
        title: getCaseAgnosticQueryParam('Title'),
        companyName: getCaseAgnosticQueryParam('CompanyName'),
      },

      numberOfRibbonCables: getCaseAgnosticQueryParam('numberOfRibbonCables'),
      meterConnectionBetweenRibbonCable: getCaseAgnosticQueryParam(
        'meterConnectionBetweenRibbonCable'
      ),
      parkingSpaceArrangement: getCaseAgnosticQueryParam('parkingSpaceArrangement'),
      checkType: getCaseAgnosticQueryParam('checkType'),
    });

    return {
      collectedData,
      browserCallback: getCaseAgnosticQueryParam('browserCallback'),
      partnerId: getCaseAgnosticQueryParam('partnerId'),
      Language: getCaseAgnosticQueryParam('Language'),
      restQueryParams: getRestQueryParams(queryParams as any),
    } as unknown as T; // @TODO fix typing
  }
}
