import { NgRedux, select } from '@angular-redux/store';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { EmStateEffect, GTMEventEm, IAppStateEm } from '@theia-cc/em/state';
import { getCurrentStepFromUrl, googleTagManagerPush } from '@theia-cc/shared/helpers';
import { AlertService, ShareLinkService } from '@theia-cc/shared/services';
import { ConfigAction, SharedStoreEffect } from '@theia-cc/shared/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import packageJson from '../../../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  public subscription: Subscription = new Subscription();
  public spinnerOptions = {
    fullScreenBackdrop: true,
  };
  private previousStep = '';

  @ViewChild('alertContainer', { read: ViewContainerRef, static: true })
  alertContainer;

  @select((state: IAppStateEm) => state.wizard.showLoadingSpinner)
  showLoadingSpinner$;

  @select((state: IAppStateEm) => state.wizard.showOfferPreviewLoadingSpinner)
  showOfferPreviewLoadingSpinner$: Observable<boolean>;

  public offerPreviewSpinnerOptions = {
    fullScreenBackdrop: true,
    backdropBackgroundColour: 'rgb(255,255,255)',
  };

  constructor(
    public action: EmStateEffect,
    public effect: SharedStoreEffect,
    public configAction: ConfigAction,
    private renderer: Renderer2,
    private router: Router,
    private alertService: AlertService,
    public shareLinkService: ShareLinkService,
    private store: NgRedux<IAppStateEm>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit() {
    this.logCurrentVersionNumberToConsole();
    this.subscription.add(
      this.router.events
        .pipe(filter(event => event instanceof RoutesRecognized))
        .subscribe((event: RoutesRecognized) => {
          this.configAction.parseQParams(event.state.root.queryParams);

          const currentStep = getCurrentStepFromUrl(event.urlAfterRedirects);
          if (currentStep) {
            this.renderer.addClass(document.body, currentStep);
          }
          if (this.previousStep) {
            this.renderer.removeClass(document.body, this.previousStep);
          }
          this.effect.setCurrentStep(currentStep);
          this.previousStep = currentStep;
        })
    );

    this.subscription.add(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        googleTagManagerPush<GTMEventEm>({
          event: 'VirtualPageview',
          virtualPageURL: `${window.location.pathname}${window.location.search}`,
          virtualPageTitle: window.location.pathname.replace('/', ''),
        });
      })
    );

    this.alertService.setViewContainerRef(this.alertContainer);
  }

  logCurrentVersionNumberToConsole() {
    console.log(
      '%c%s',
      'color: #03518c; font-size: 14px;',
      `${packageJson.name}-em version: ${packageJson.version}`
    );

    this.configAction.storeVersionNumber(`${packageJson.name}-em version: ${packageJson.version}`);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  generateShareLink() {
    return (): string =>
      this.shareLinkService.generateShareLink(this.document.location, this.store.getState());
  }
}
