import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { NgModule } from '@angular/core';
import { reduceReducers } from '@theia-cc/shared/helpers';
import { BackendService, CantonService, UserInfoService } from '@theia-cc/shared/services';
import {
  ReducerRegistry,
  configReducer,
  deviceReducer,
  errorHandler,
  languageReducer,
  wizardReducer,
} from '@theia-cc/shared/store';
import reduxThunk from 'redux-thunk';
import { INITIAL_STATE, INITIAL_STATE_CORE, StateCore } from './state';

export const coreReducers = {
  config: reduceReducers(INITIAL_STATE.config, configReducer),
  currentLanguage: languageReducer,
  wizard: wizardReducer,
  device: deviceReducer,
};

@NgModule({
  imports: [NgReduxModule],
  providers: [BackendService, CantonService, UserInfoService, ReducerRegistry],
})
export class StoreModule {
  constructor(
    private store: NgRedux<StateCore>,
    private reducerRegistry: ReducerRegistry<StateCore>,
    devTools: DevToolsExtension
  ) {
    store.configureStore(
      state => state,
      INITIAL_STATE_CORE,
      [reduxThunk, errorHandler],
      devTools.isEnabled() ? [devTools.enhancer()] : []
    );
    reducerRegistry.registerReducers(coreReducers);
  }
}
