import { IAppStateEm } from '@theia-cc/em/state';

export const INITIAL_STATE: IAppStateEm = {
  config: {
    envVariables: {
      appKey: null,
      localeStyle: null,
      enableCallbackHeader: true,
      enablePhoneHeader: true,
    },
    queryParams: {
      partnerId: null,
      origin: null,
    },
    org: {
      name: null,
      contactPhone: null,
      contactEmail: null,
      logoImageUrl: null,
      logoLinkUrl: {
        de: null,
        fr: null,
        it: null,
      },
      stylesheetUrl: null,
      faviconUrl: null,
      contactOfferImageUrls: null,
      successPageUrls: {
        de: null,
        fr: null,
        it: null,
      },
    },
    styles: null,
    defaultLanguage: null,
    steps: [null],
    configLoaded: false,
    versionNumber: null,
    hubspotTrackingId: null,
    theme: null,
    styleOverridesFileLink: null,
    useCustomThemeOverrideStylesheet: false,
    generalTranslations: null,
    specificTranslations: null,
  },
  currentLanguage: null,
  wizard: {
    showLoadingSpinner: false,
    showOfferPreviewLoadingSpinner: false,
    allowNextStep: false,
    currentStep: null,
    previousStep: null,
    currentStepIdx: null,
    lastStep: null,
    totalSteps: null,
    currentStepData: null,
    navbar: {
      primaryButton: {
        name: null,
        action: null,
        facet: null,
        disabled: null,
        hidden: null,
      },
      secondaryButton: {
        name: null,
        action: null,
        facet: null,
        disabled: null,
        hidden: null,
      },
      showShareButton: true,
    },
    isSharedOfferPreview: undefined,
  },
  device: {
    device: null,
    browser: null,
  },
  collectedData: {
    whereToInstallCharger: null,
    numberOfParkingspacesToBeEquippedResidential: null,
    variantIdEm: null,
    parkingspace: null,
    poweroutlet: null,
    connectpv: null,
    nboFetchedEm: [],
    user: {
      companyName: null,
      email: null,
      emailDisclaimer: null,
      firstName: null,
      lastName: null,
      telephone: null,
      title: null,
    },
    userAddress: {
      zip: null,
      place: null,
      street: null,
      lat: null,
      lon: null,
      x: null,
      y: null,
      addressString: null,
      hasGeometry: null,
    },
    fuseBox: null,
    nboLeadIdEm: null,
    leadStatus: null,
    leadTag: null,
    leadTracers: null,
    leadComment: null,
    leadCallbackTime: {
      IsCallbackTimeMorning: null,
      IsCallbackTimeAfternoon: null,
      IsCallbackTimeEvening: null,
      IsCallbackTimeWeekend: null,
    },
    chargingPower: null,
    orderType: null,
    selectedTemplateEm: null,
    totalNumberOfParkingspaces: null,
    numberOfParkingspacesToBeEquippedCommercial: null,
    numberOfParkingspacesPreparedToBeElectrified: null,
    distanceFuseboxToChargingstation: null,
    distanceFuseboxToFlatRibbonCable: null,
    selectedTemplateIdEm: null,
    numberOfRibbonCables: null,
    meterConnectionBetweenRibbonCable: null,
    parkingSpaceArrangement: null,
    checkType: null,
  },
};

const { collectedData, ...rest } = INITIAL_STATE;
export const INITIAL_STATE_CORE = rest;

export type StateCore = Omit<IAppStateEm, 'collectedData'>;
