import { caseAgnosticQueryParam } from '@theia-cc/shared/helpers';
import { IWizardBase } from '@theia-cc/shared/models';

export function skipCompareTechnologies({ config: { queryParams } }): boolean {
  return caseAgnosticQueryParam(queryParams)('compareTechnologies') !== 'true';
}

export function newVersion({ wizard }: { wizard: IWizardBase }): boolean {
  return +wizard?.version > 1;
}
